/* Mixins */
.report-index-component {
  min-width: 1500px !important;
  min-height: calc(100vh - 48px);
  background-color: #0c141b;
}
.report-index-component .report-header .page-breadcrumb {
  background-color: #191F28;
  color: #fff;
  border-bottom: none;
}
.report-index-component .report-header .ant-breadcrumb a {
  color: #91979E;
}
.report-index-component .report-header .ant-breadcrumb span:last-child {
  color: #fff;
}